<template>
    <div v-if="HDefinitions[0]" class="bottomContent border-l-2 border-white flex overflow-x-auto w-full">
        <Definition v-for="(item, index) in HDefinitions[0]" :key="index" :data="item" />
    </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { isEmpty, get } from 'lodash'
import Definition from './Definition.vue'
import useRoadmapConcept from '@/views/screens/roadmap/strategicRoadmap/roadmapConcept/useRoadmapConcept.js'
import useHighLevelConcept from '@/views/screens/roadmap/strategicRoadmap/roadmapConcept/useHighLevelConcept.js'

const { strategy } = useHighLevelConcept()

const HDefinitions = computed(() => strategy.value.definitions)
const { horizontalDefinitions } = useRoadmapConcept()
const sliderDefinition = ref({})
const businessDefinition = ref({})
const definitions = ref({})

if(!isEmpty(horizontalDefinitions.value)) {
    sliderDefinition.value = horizontalDefinitions.value.items.filter(item => item.type==1)
    sliderDefinition.value = sliderDefinition.value[0]
    businessDefinition.value = horizontalDefinitions.value.items.filter(item => item.type==3)
    businessDefinition.value = !isEmpty(businessDefinition.value) ? businessDefinition.value[0] : {}
}

if(!isEmpty(sliderDefinition.value.range_with_definition))
{
    definitions.value = !!sliderDefinition.value.range_with_definition && sliderDefinition.value.range_with_definition.map((item, index) => {
        if(get(businessDefinition.value, `range_with_definition[${index}]`)){
            item.description = get(businessDefinition.value, `range_with_definition[${index}].definition`)
            return item
        }
    })
}
</script>

<style scoped>
.bottomContent{
    width: 1143px;
}
</style>