<template>
    <svg width="1135" height="635" viewBox="0 0 1135 635" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1134 635V0" stroke="white" stroke-width="2"/>
        <path d="M2 0.999977L1135 0.999878" stroke="white" stroke-width="2"/>
        <path d="M2 572.5H1133" stroke="white"/>
        <path d="M2 509.5H1133" stroke="white"/>
        <path d="M2 130.5H1133" stroke="white"/>
        <path d="M2 192.5H1133" stroke="white"/>
        <path d="M2 255.5H1133" stroke="white"/>
        <path d="M2 383.5H1133" stroke="white"/>
        <path d="M2 446.5H1133" stroke="white"/>
        <path d="M2 66.5H1133" stroke="white"/>
        <path d="M379 635V3" stroke="white" stroke-width="2"/>
        <path d="M65.5 635V2" stroke="white"/>
        <path d="M127.5 635V2" stroke="white"/>
        <path d="M190.5 635V2" stroke="white"/>
        <path d="M253.5 635V2" stroke="white"/>
        <path d="M316.5 635V2" stroke="white"/>
        <path d="M504.5 635V2" stroke="white"/>
        <path d="M441.5 635V2" stroke="white"/>
        <path d="M567.5 635V2" stroke="white"/>
        <path d="M627.5 635V2" stroke="white"/>
        <path d="M693.5 635V2" stroke="white"/>
        <path d="M881.5 635V2" stroke="white"/>
        <path d="M818.5 635V2" stroke="white"/>
        <path d="M944.5 635V2" stroke="white"/>
        <path d="M1006.5 635V2" stroke="white"/>
        <path d="M1069.5 635V2" stroke="white"/>
        <path opacity="0.35" d="M378 2H2V633H378V2Z" :fill="strategy.settings?.colors ? strategy.settings.colors[0] : ''" />
        <path d="M1 634V0" stroke="white" stroke-width="2"/>
        <path opacity="0.35" d="M754 2H380V633H754V2Z" :fill="strategy.settings?.colors ? strategy.settings.colors[1] : ''" />
        <path opacity="0.35" d="M1133 2H756V633H1133V2Z" :fill="strategy.settings?.colors ? strategy.settings.colors[2] : ''" />
        <path d="M2 316H1133" stroke="white" stroke-width="2"/>
        <path d="M755 635V2" stroke="white" stroke-width="2"/>
    </svg>        
</template>

<script setup>
import useHighLevelConcept from '@/views/screens/roadmap/strategicRoadmap/roadmapConcept/useHighLevelConcept.js'
const { strategy } = useHighLevelConcept(true)
</script>

<style lang="scss" scoped>

</style>