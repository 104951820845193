<template>
    <div class="left overflow-y-auto pr-4" style="height: 700px;">
        <div
            style="padding-left: 18px;"
            v-for="(firstLevel, i) in systemData.children"
            :key="firstLevel.id"
            class="overflow-y-scroll py-3 border-white border-b-2"
            :class="i == 0 && 'border-t-2'"
        >
            <h1 class="text-primary-one fw-800 fs-14 mb-3">
                {{ firstLevel.index_id }}{{ firstLevel.index_code }} {{ firstLevel.title }}
            </h1>
          <div v-for="(level, index) in firstLevel.children" :key="index">
            <LevelData
                :level="level"
                :systemDates="systemData.dates"
                color="#2BAD7E"
            />
          </div>
        </div>
    </div>
</template>

<script setup>
    import LevelData from '@/components/roadmap/strategicRoadmap/components/LavelData.vue'
    import useHighLevelConcept from '@/views/screens/roadmap/strategicRoadmap/roadmapConcept/useHighLevelConcept.js'

    const { systemData } = useHighLevelConcept()
</script>

<style scoped>
.left{
   min-width: 318px;
}
</style>
