<template>
    <BaseLayout
        :sidebarVisibility="sidebarVisibility"
        @exitFullScreen="sidebarVisibility = true"
    >
        <template #sidebar>
            <!-- :inactiveItems="inactiveNavItems" -->
            <Sidebar
                :sidebarItems="sidebarItems"
                :switchSidebarComponent="switchSidebarComponent"
            >
                <component
                    v-bind="sidebarProps"
                    :is="activeSidebarComponent"
                    :project="project"
                />
            </Sidebar>
        </template>

        <div class="header-block">
            <Header :configuration="strategy" :scenarios="scenarios"/>
            <div class="flex items-center gap-6" >
                <h1 style="width: 294px;" class="bg-transparent fs-24 fw-800 h-10 pr-2">
                    High Level Roadmap
                </h1>
                <Toolbar style="margin-bottom: 4px;" class="-mr-3" />
                <StyleAndFilter style="margin-bottom: 4px;" />
            </div>
            <GraphWrapper>
                <div>
                    <div class="">
                        <Top 
                            :configuration="strategy"
                            :modules="modules"
                            :phases="systemData.phases"
                            @onModuleChange="handleModuleChange"
                            style="margin-top: 10px;margin-bottom: 6px;"
                        />
                    </div>
                    <div class="flex">
                        <LeftSide class="pr-5" />
                        <RoadmapConcept />
                    </div>
                </div>
            </GraphWrapper>
        </div>
    </BaseLayout>
</template>

<script setup>
    import { computed, ref } from 'vue';
    import { useSidebar } from './useSidebar';
    import { BaseLayout, Sidebar, Header, Toolbar, GraphWrapper } from './useImporter';
    import StyleAndFilter from '@/components/roadmap/strategicRoadmap/roadmapConcept/StyleAndFilter.vue'
    //refactor
    import LeftSide from '@/components/roadmap/strategicRoadmap/components/LeftSide.vue';
    import Top from '@/components/roadmap/strategicRoadmap/components/Top.vue';
    import RoadmapConcept from '@/components/roadmap/strategicRoadmap/roadmapConcept/Index.vue'
    import useHighLevelConcept from '@/views/screens/roadmap/strategicRoadmap/roadmapConcept/useHighLevelConcept.js'
    import store from "@/store"

    const { 
        systemData, 
        setSystemData,
        handleModuleChange, 
        modules, 
        module,
        scenarios,
        setModuleId,
        strategy,
        parameters,
    } = useHighLevelConcept(true)


    const {
        switchSidebarComponent,
        activeSidebarComponent,
        currentSidebar,
        propsData,
        sidebarVisibility,
        inactiveNavItems,
        sidebarItems
    } = useSidebar();

    const sidebarPropsDataSet = ref({
        'ModuleList': {
            modules,
            module: module,
            setModuleId
        },
        'Properties': {
            strategy,
            module,
            properties: parameters,
            project: {},
            setSystemData
        }
    });

    const project = computed(() => {
        return store.getters["project/project"]
    })

    const sidebarProps = computed(() =>  {
        return {
            ...sidebarPropsDataSet.value[currentSidebar.value],
            ...propsData.value
        }
    })
</script>
<style scoped>
.header-block {
  margin-top: -5.2rem;
}
</style>
