<template>
    <div class="flex items-center gap-3 justify-start h-full">
        <button @click="handleCircleInfo" class="border border-primary-four bg-white text-secondary-one w-6 h-6 grid place-content-center rounded">
            <svg class="w-3" xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 30 20" fill="none">
                <path d="M2 0.5H28C28.8284 0.5 29.5 1.17157 29.5 2V18C29.5 18.8284 28.8284 19.5 28 19.5H2C1.17157 19.5 0.5 18.8284 0.5 18V2C0.5 1.17157 1.17157 0.5 2 0.5Z" fill="#F9F9F9" stroke="black"/>
                <path d="M15.0117 6.97356C14.4704 6.97356 14.0364 6.82889 13.7097 6.53955C13.3831 6.25022 13.2197 5.88622 13.2197 5.44755C13.2197 4.99955 13.3831 4.63089 13.7097 4.34155C14.0364 4.04289 14.4704 3.89355 15.0117 3.89355C15.5437 3.89355 15.9731 4.04289 16.2997 4.34155C16.6264 4.63089 16.7897 4.99955 16.7897 5.44755C16.7897 5.88622 16.6264 6.25022 16.2997 6.53955C15.9731 6.82889 15.5437 6.97356 15.0117 6.97356ZM16.5517 7.60356V15.4996H13.4437V7.60356H16.5517Z" fill="black"/>
            </svg>
        </button>

        <button class="border border-primary-four bg-white text-secondary-one w-6 h-6 grid place-content-center rounded">
            <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="3.15039" y="2.7998" width="7.7" height="1.1" fill="#3D3D3D"/>
                <rect x="3.15039" y="6.09961" width="7.7" height="1.1" fill="#00A7FE"/>
            </svg>
        </button>

        <div v-if="strategy.settings?.colors" class="border border-primary-four bg-white text-secondary-one w-6 h-6 grid place-content-center rounded relative" v-click-outside="() => { showColorPicker = false }">
            <button @click="showColorPicker = !showColorPicker" class="relative">
                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0_23923_170716" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="12" height="8">
                        <rect width="12" height="8" fill="#C4C4C4"/>
                    </mask>
                    <g mask="url(#mask0_23923_170716)">
                        <rect x="6.19336" width="5.80645" height="4" fill="#19A77C"/>        
                        <rect width="5.80645" height="3.5" transform="matrix(1 0 0 -1 6.19336 8)" fill="#0085FF"/>
                        <rect width="5.80645" height="4" transform="matrix(-1 0 0 1 5.80664 0)" fill="#D6D6D6"/>
                        <rect x="5.80664" y="8" width="5.80645" height="3.5" transform="rotate(-180 5.80664 8)" fill="#FE5656"/>
                    </g>
                </svg>
            </button>
            <div v-show="showColorPicker" class="colorPickers absolute left-0 top-full mt-1 flex flex-col gap-1 z-30 bg-white p-1 shadow">
                <label class="w-4 h-3 cursor-pointer border" :style="{backgroundColor: strategy.settings.colors[0]}" >
                    <input type="color" class="absolute opacity-0" v-model="strategy.settings.colors[0]" @input="changeColor" >
                </label>
                <label class="w-4 h-3 cursor-pointer border" :style="{backgroundColor: strategy.settings.colors[1]}" >
                    <input type="color" class="absolute opacity-0" v-model="strategy.settings.colors[1]"  @input="changeColor" >
                </label>
                <label class="w-4 h-3 cursor-pointer border" :style="{backgroundColor: strategy.settings.colors[2]}">
                    <input type="color" class="absolute opacity-0" v-model="strategy.settings.colors[2]" @input="changeColor" >
                </label>
            </div>
        </div>


        <button class="border border-primary-four bg-white text-secondary-one w-6 h-6 grid place-content-center rounded">
            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="5.9082" width="2.3636" height="12" transform="rotate(-90 0 5.9082)" fill="#3D6A83"/>
                <rect y="2.36328" width="2.3636" height="12" transform="rotate(-90 0 2.36328)" fill="#2B80AD"/>
                <rect opacity="0.5" y="9.4541" width="2.3636" height="12" transform="rotate(-90 0 9.4541)" fill="#6A7881"/>
                <rect opacity="0.5" y="13" width="2.3636" height="12" transform="rotate(-90 0 13)" fill="#818487"/>
            </svg>                
        </button>
    </div>
</template>

<script setup>
    import { ref } from 'vue'
    import router from '@/routes/routes.js'
    import useConceptCanvas from '@/components/roadmap/strategicRoadmap/roadmapConcept/useConceptCanvas.js'
    import useHighLevelConcept from '@/views/screens/roadmap/strategicRoadmap/roadmapConcept/useHighLevelConcept.js'
    
    const route = router.currentRoute;
    const { getStrategy, strategy, updateStrategy } = useHighLevelConcept(true)
    const { showDetail } = useConceptCanvas()
    const showColorPicker = ref(false)
    const handleCircleInfo = () => {
        showDetail.value = !showDetail.value
        getStrategy(route.params.id)
    }

    let timeoutId = null
    const changeColor = () => {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            updateStrategy(strategy.value)
        }, 1000)
    }
</script>