<template>
    <div class="absolute top-0 left-0 right-0 bottom-0 pointer-events-none z-50">
        <div class="absolute z-20 top-0 w-full top-input flex justify-between">
            <input 
                type="text" 
                class="bg-transparent mt-6 ml-6 fw-800 fs-16 fw-800 fs-16 text-white pointer-events-auto" 
                placeholder="Add text"
                v-model="hintsText[0]"
                @blur="updateStrategy(strategy)"
            >
            <input 
                type="text" 
                class="bg-transparent mt-6 mr-6 fw-800 fs-16 text-right fw-800 fs-16 text-white pointer-events-auto" 
                placeholder="Add text"
                v-model="hintsText[1]"
                @blur="updateStrategy(strategy)"
            >
        </div>
        <div class="absolute z-20 bottom-0 w-full top-input flex justify-between">
            <input 
                type="text" 
                class="bg-transparent mb-6 ml-6 fw-800 fs-16 fw-800 fs-16 text-white pointer-events-auto" 
                placeholder="Add text"
                v-model="hintsText[2]"
                @blur="updateStrategy(strategy)"
            >
            <input 
                type="text" 
                class="bg-transparent mb-6 mr-6 fw-800 fs-16 text-right fw-800 fs-16 text-white pointer-events-auto" 
                placeholder="Add text"
                v-model="hintsText[3]"
                @blur="updateStrategy(strategy)"
            >
        </div>
    </div>
</template>

<script setup>
    import { watchEffect, ref } from 'vue'
    import useHighLevelConcept from '@/views/screens/roadmap/strategicRoadmap/roadmapConcept/useHighLevelConcept.js'
    import { isEmpty } from 'lodash'
    
    const hintsText = ref([
        "Add text",
        "Add text",
        "Add text",
        "Add text",
    ])
    const { strategy, updateStrategy } = useHighLevelConcept()
    
    watchEffect(async () => {
        let localHintsText = await strategy.value.hints_text
        if(!isEmpty(localHintsText)){
            hintsText.value = localHintsText
        }
    })
</script>

<style scoped>
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: var(--color);
    }
    ::-moz-placeholder { /* Firefox 19+ */
        color: var(--color);
    }
    :-ms-input-placeholder { /* IE 10+ */
        color: var(--color);
    }
    :-moz-placeholder { /* Firefox 18- */
        color: var(--color);
    }
</style>